<template>
    <div class="app">
        <router-view></router-view>
    </div>
</template>

<script>
</script>

<style>
.app {
    background-image: url('@/assets/bgimg1.png');
    background-repeat: repeat;
    background-size: 300%;
    height: auto;
    min-height: 100vh;
}
</style>

<style scoped>
* {
    margin: 0;
    padding: 0;
}

</style>
