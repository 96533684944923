<template>
    <div class="logo-main">
        <img src="@/assets/logoicon.svg" alt="" class="logo-img">
        <h1>Портал ГБОУ Школы <span style="white-space: nowrap;">"Покровский квартал"</span></h1>
        <span>Личный кабинет поступающего в Распределенный лицей</span>
    </div>
</template>

<script>

</script>

<style scoped>
.logo-main {
    text-align: center;
    margin-top: 10%;
    margin-bottom: 10%;
}
.logo-img {
    width: 75px;
    margin-bottom: 30px;
}
h1 {
    font-size: 22px;
}
span {
    font-size: 22px;
}
</style>